import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAzReAqVaoYqDS9Jn1pfzIZIcmn0yLMo3o",
  authDomain: "corded-guild-395602.firebaseapp.com",
  projectId: "corded-guild-395602",
  storageBucket: "corded-guild-395602.appspot.com",
  messagingSenderId: "324097046614",
  appId: "1:324097046614:web:317635dd048eff302823f7",
  measurementId: "G-ZYLLC09V98"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;
