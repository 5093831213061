// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  color: #cccccc;
  font-family: 'Prompt', Helvetica;
  font-size: 20px;
  font-weight: 200;
}

.recording {
  color: firebrick;
  padding-left: 1.2em;
}

.recording::before {
  content: '🔴';
  margin-right: 3px;
  animation: recording 600ms alternate infinite;
}
@keyframes recording {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}

.actions {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.text-white {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gCAAgC;EAChC,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,6CAA6C;AAC/C;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,YAAY;AACd","sourcesContent":[".app {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.header {\n  color: #cccccc;\n  font-family: 'Prompt', Helvetica;\n  font-size: 20px;\n  font-weight: 200;\n}\n\n.recording {\n  color: firebrick;\n  padding-left: 1.2em;\n}\n\n.recording::before {\n  content: '🔴';\n  margin-right: 3px;\n  animation: recording 600ms alternate infinite;\n}\n@keyframes recording {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0.2;\n  }\n}\n\n.actions {\n  display: flex;\n  justify-content: center;\n  gap: 30px;\n}\n\n.text-white {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
