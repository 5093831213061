// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1,
h2,
h4,
p {
  color: white;
}

.home {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
}

.settings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin-bottom: 30px;
}

.start-btn {
  padding: 30px;
}

.conversation-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shared-conversation-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  text-align: center;
}

@keyframes pulse {
  0% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.avatar-wrapper {
  position: relative;
  display: inline-block;
}

.pulsating-avatar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 50%;
  animation: pulse 1s infinite;
  opacity: 0;
  z-index: -1;
}
:root {
  --nextui--navbarHeight: 0px;
}

label {
  color: #e0e0e0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/styles.css"],"names":[],"mappings":"AAAA;;;;EAIE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,qBAAqB;IACrB,YAAY;EACd;EACA;IACE,qBAAqB;IACrB,UAAU;EACZ;AACF;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,kBAAkB;EAClB,4BAA4B;EAC5B,UAAU;EACV,WAAW;AACb;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB","sourcesContent":["h1,\nh2,\nh4,\np {\n  color: white;\n}\n\n.home {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 50vw;\n}\n\n.settings {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  width: 50vw;\n  margin-bottom: 30px;\n}\n\n.start-btn {\n  padding: 30px;\n}\n\n.conversation-page {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.shared-conversation-page {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.center {\n  text-align: center;\n}\n\n@keyframes pulse {\n  0% {\n    transform: scale(1.2);\n    opacity: 0.7;\n  }\n  100% {\n    transform: scale(1.5);\n    opacity: 0;\n  }\n}\n\n.avatar-wrapper {\n  position: relative;\n  display: inline-block;\n}\n\n.pulsating-avatar::after {\n  content: '';\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  background-color: white;\n  border-radius: 50%;\n  animation: pulse 1s infinite;\n  opacity: 0;\n  z-index: -1;\n}\n:root {\n  --nextui--navbarHeight: 0px;\n}\n\nlabel {\n  color: #e0e0e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
