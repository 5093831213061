// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  color: #e0e0e0;
}

.select {
  background-color: #1b2134;
  color: #ffffff;
  border-color: #1b2134;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MediaDevices/style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,qBAAqB;EACrB,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["label {\n  color: #e0e0e0;\n}\n\n.select {\n  background-color: #1b2134;\n  color: #ffffff;\n  border-color: #1b2134;\n  width: 100%;\n  padding: 10px;\n  border-radius: 5px;\n  margin-top: 20px;\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
