/**
 * src/components/TextView/index.jsx
 * show chat log. User can send message and switch to CallView.
 *
 * created by Lynchee on 7/16/23
 */

import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import {
  TbPower,
  TbPhoneCall,
  TbMicrophone,
  TbPlayerStopFilled,
  TbKeyboard,
  TbShare2,
} from 'react-icons/tb';
import IconButton from '../Common/IconButton';
import { MdVoiceChat } from 'react-icons/md';
import Button from '../Common/Button';
import { useNavigate } from 'react-router-dom';
import Feedback from '../Feedback';
import logo from "../../assets/svgs/logo.svg";

const TextView = ({
  selectedCharacter,
  send,
  isPlaying,
  isThinking,
  isResponding,
  stopAudioPlayback,
  textAreaValue,
  setTextAreaValue,
  imageUrl,
  setImageUrl,
  messageInput,
  setMessageInput,
  handleDisconnect,
  setIsCallView,
  useSearch,
  setUseSearch,
  callActive,
  startRecording,
  stopRecording,
  messageId,
  token,
  sessionId,
}) => {
  const navigate = useNavigate();
  const [keyboard, SetKeyboard] = useState(false);
  const chatWindowRef = useRef(null);
  const talking = useRef(false);

  // always show the latest chat log
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [textAreaValue]);

  useEffect(() => {
    if (
      isThinking &&
      !textAreaValue.endsWith(`${selectedCharacter.name} is thinking...`)
    ) {
      setTextAreaValue(
        prevState => prevState + `${selectedCharacter.name} is thinking...`
      );
    } else if (
      !isThinking &&
      textAreaValue.endsWith(`${selectedCharacter.name} is thinking...`)
    ) {
      setTextAreaValue(prevState =>
        prevState.substring(
          0,
          prevState.length - `${selectedCharacter.name} is thinking...`.length
        )
      );
    }
  }, [isThinking, textAreaValue]);

  const handlePowerOffClick = () => {
    navigate('/');
    handleDisconnect();
  };

  // send message to server. stop audio if it's playing to interrupt character.
  const sendMessage = () => {
    setTextAreaValue(prevState => prevState + `You> ${messageInput}`);
    send(messageInput);
    setMessageInput('');
    if (isPlaying) {
      stopAudioPlayback();
    }
  };

  const handleSendClick = () => {
    if (messageInput.trim() !== '') {
      sendMessage();
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSendClick();
    }
  };

  const handleInputChange = event => {
    setMessageInput(event.target.value);
  };

  const handlePushTalk = () => {
    if (!talking.current) {
      startRecording();
      talking.current = true;
      if (isPlaying) {
        stopAudioPlayback();
      }
    } else {
      stopRecording();
      talking.current = false;
    }
  };

  const handleKeyboardClick = () => {
    SetKeyboard(true);
  };

  const handleAudioClick = () => {
    SetKeyboard(false);
  };

  return (
    <div className='text-screen'>
      <div><img src={imageUrl} alt='material-image' style={{ maxWidth: '90vw',maxHeight:'50vh', margin: '10px' }} /></div>
      <textarea
        className='chat-window'
        readOnly
        draggable='false'
        ref={chatWindowRef}
        value={textAreaValue}
      ></textarea>
      {/*<Feedback messageId={messageId} token={token} />*/}
      {/*<div className='input-container'>*/}
      {/*  <div className='message-input-container'>*/}
      {/*    <input*/}
      {/*      className='message-input'*/}
      {/*      type='text'*/}
      {/*      placeholder='Type your message'*/}
      {/*      value={messageInput}*/}
      {/*      onChange={handleInputChange}*/}
      {/*      onKeyDown={handleKeyDown}*/}
      {/*    />*/}
      {/*    <span className='focus-border'>*/}
      {/*      <i></i>*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*  {!callActive.current && (*/}
      {/*    <div>*/}
      {/*      {keyboard ? (*/}
      {/*        <IconButton*/}
      {/*          Icon={MdVoiceChat}*/}
      {/*          className='icon-blue'*/}
      {/*          onClick={handleAudioClick}*/}
      {/*        />*/}
      {/*      ) : (*/}
      {/*        <IconButton*/}
      {/*          Icon={TbKeyboard}*/}
      {/*          className='icon-blue'*/}
      {/*          onClick={handleKeyboardClick}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}

      {!callActive.current && !keyboard ? (
        <IconButton
          Icon={talking.current ? TbPlayerStopFilled : TbMicrophone}
          className={`${talking.current ? 'recording-animation' : 'icon-blue'}`}
          bgcolor={`${talking.current ? 'red' : 'default'}`}
          onClick={handlePushTalk}
        />
      ) : (
        <Button onClick={handleSendClick} name='Send Message' />
      )}

      {/*<div className='options-container'>*/}
      {/*  <IconButton*/}
      {/*    Icon={TbPower}*/}
      {/*    className='icon-red'*/}
      {/*    onClick={handlePowerOffClick}*/}
      {/*  />*/}
      {/*  <IconButton*/}
      {/*    Icon={TbPhoneCall}*/}
      {/*    className='icon-blue'*/}
      {/*    onClick={() => setIsCallView(true)}*/}
      {/*    disabled={talking.current}*/}
      {/*  />*/}
      {/*  <IconButton*/}
      {/*    Icon={TbShare2}*/}
      {/*    disabled={isResponding}*/}
      {/*    onClick={() =>*/}
      {/*      window.open(`/shared?session_id=${sessionId}`, '_blank')*/}
      {/*    }*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};

export default TextView;
