/**
 * src/pages/Settings.jsx
 *
 * created by Lynchee on 7/28/23
 */

// TODO: user can access this page only if isConnected.current and selectedCharacter

import React, { useState, useEffect } from 'react';
import Languages from '../components/Languages';
import MediaDevices from '../components/MediaDevices';
import Models from '../components/Models';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './styles.css';
import CommunicationMethod from '../components/CommunicationMethod';
import Search from '../components/Search';
import lz from 'lz-string';
import Select from 'react-select';
const Settings = ({
  setSelectedCharacter,
  isMobile,
  preferredLanguage,
  setPreferredLanguage,
  selectedDevice,
  setSelectedDevice,
  selectedModel,
  setSelectedModel,
  useSearch,
  setUseSearch,
  send,
  connect,
  setIsCallView,
  shouldPlayAudio,
}) => {
  const navigate = useNavigate();
  const [commMethod, setCommMethod] = useState('Text');

  const { search } = useLocation();
  const { character = '' } = queryString.parse(search);


  // 书籍选项数据
  const bookOptions = [
    { value: 'Travel English', label: 'Travel English' },
    { value: 'NCE B1', label: 'NCE B1' },
  ];

  // 单位选项数据
  const unitOptions = {
    'Travel English': [
      { value: '1', label: 'Travel English Unit 1' },
      { value: '2', label: 'Travel English Unit 2' },
    ],
    'NCE B1': [
      { value: '3', label: 'NCE B1 Unit 1' },
      { value: '4', label: 'NCE B1 Unit 2' },
    ],
  };

    // 自定义 React Select 的样式
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#1b2134', // 自定义背景颜色
      color: '#ffffff', // 自定义文本颜色
      borderColor: '#1b2134', // 自定义边框颜色
      width: '100%', // 自定义宽度
      padding: '10px', // 自定义内边距
      borderRadius: '5px', // 自定义边框圆角
      marginTop: '20px', // 自定义上边距
      marginBottom: '30px', // 自定义下边距
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#ffffff', // 自定义文本颜色
    }),
    // 更多样式属性可根据需要自定义
  };

  // 创建状态来存储所选的书籍和单位，并设置默认值
  const [selectedBook, setSelectedBook] = useState(bookOptions[0]); // 设置默认书籍
  const [selectedUnit, setSelectedUnit] = useState(
    unitOptions[bookOptions[0].value][0] // 设置默认单位为所选书籍的第一个单位
  );

  // 处理书籍选择
  const handleBookChange = (selectedOption) => {
    setSelectedBook(selectedOption);
    setSelectedUnit(unitOptions[selectedOption.value][0]); // 设置默认单位为所选书籍的第一个单位
  };

  // 处理单位选择
  const handleUnitChange = (selectedOption) => {
    setSelectedUnit(selectedOption);
  };

  useEffect(() => {
    const selectedCharacter = JSON.parse(
      lz.decompressFromEncodedURIComponent(character)
    );
    setSelectedCharacter(selectedCharacter);

    if (!selectedCharacter) {
      console.log('No character selected');
      navigate('/');
    }
  }, [setSelectedCharacter, character, navigate]);

  const handleStartClick = async () => {
    await connect();

    // TODO(UI): Show loading animation

    const interval = setInterval(() => {
      // display callview
      // setIsCallView(commMethod === 'Call');

      shouldPlayAudio.current = true;
      clearInterval(interval);

      // TODO(UI): Hide loading animation
    }, 500);

    navigate('/conversation');
  };

  return (
    <div className='settings'>
      <h2 className='center'>Confirm your setting</h2>

      {/*<CommunicationMethod*/}
      {/*  commMethod={commMethod}*/}
      {/*  setCommMethod={setCommMethod}*/}
      {/*/>*/}

      {/*<Languages*/}
      {/*  preferredLanguage={preferredLanguage}*/}
      {/*  setPreferredLanguage={setPreferredLanguage}*/}
      {/*/>*/}

      <MediaDevices
        selectedDevice={selectedDevice}
        setSelectedDevice={setSelectedDevice}
      />

      {/*<Models*/}
      {/*  isMobile={isMobile}*/}
      {/*  selectedModel={selectedModel}*/}
      {/*  setSelectedModel={setSelectedModel}*/}
      {/*/>*/}

      {/*<Search useSearch={useSearch} setUseSearch={setUseSearch} send={send} />*/}
      <div className='devices-container'>
        <label>Book</label>
        <Select id='book-selection'
          value={selectedBook}
          onChange={handleBookChange}
          options={bookOptions}
          classNamePrefix='custom-select' // 设置自定义前缀
          styles={customStyles} // 使用自定义样式
        />

        <label>Unit</label>
        <Select id='unit-selection'
          value={selectedUnit}
          onChange={handleUnitChange}
          options={unitOptions[selectedBook.value]} // 根据所选书籍动态加载单位选项
          classNamePrefix='custom-select' // 设置自定义前缀
          styles={customStyles} // 使用自定义样式
        />
        {selectedUnit && <div>Selected unit: {selectedUnit.label}</div>}
      </div>

      <Button
        variant='contained'
        onClick={handleStartClick}
        fullWidth
        size='large'
        sx={{
          textTransform: 'none',
        }}
      >
        Get Started
      </Button>
    </div>
  );
};

export default Settings;
